.match-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.match-popup {
  background: var(--bg-gradient);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 90%;
  max-width: 400px;
  animation: scaleIn 0.3s ease-out;
  margin: 0 10px; /* Add margin for very small screens */
}

.match-popup-content {
  text-align: center;
  position: relative; /* Add position relative to establish stacking context */
}

.match-popup h2 {
  color: var(--text-primary);
  font-size: 1.8rem;
  margin-bottom: 0.75rem;
}

.match-popup p {
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.match-timer {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem; /* Increase bottom margin to create more space */
  position: relative;
  z-index: 1; /* Lower z-index than buttons */
  height: 80px; /* Fixed height to prevent layout shifts */
}

.timer-circle {
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-circle svg {
  transform: rotate(-90deg);
  overflow: hidden; /* Change from visible to hidden */
  position: absolute;
  width: 100%;
  height: 100%;
}

.timer-circle path {
  transition: stroke-dasharray 1s linear;
}

.timer-circle span {
  position: absolute;
  color: var(--text-primary);
  font-size: 1.8rem;
  font-weight: bold;
  z-index: 2; /* Make sure the number is above the circle */
}

.match-buttons {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  flex-wrap: wrap; /* Allow buttons to wrap on very small screens */
  position: relative;
  z-index: 10; /* Higher z-index to ensure buttons are clickable */
  margin-top: 1rem; /* Add top margin to create more separation */
}

.match-buttons button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-width: 110px; /* Ensure buttons have a minimum width */
  touch-action: manipulation; /* Improve touch handling */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  position: relative; /* Ensure buttons have their own stacking context */
}

/* Make buttons larger on mobile for easier tapping */
@media (max-width: 480px) {
  .match-buttons button {
    padding: 1rem 1.5rem;
    margin: 0.25rem;
    flex: 1 0 40%; /* Allow buttons to grow but maintain minimum size */
    font-size: 1.1rem;
  }
  
  .match-popup {
    padding: 1.25rem;
  }
  
  .match-popup h2 {
    font-size: 1.6rem;
  }
  
  /* Increase spacing between timer and buttons on mobile */
  .match-timer {
    margin-bottom: 3rem;
  }
}

.accept-button {
  background: var(--success-color, #28a745);
  color: white;
}

.accept-button:hover {
  background: var(--success-color-dark, #218838);
  transform: translateY(-2px);
}

.decline-button {
  background: var(--danger-color, #dc3545);
  color: white;
}

.decline-button:hover {
  background: var(--danger-color-dark, #c82333);
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
