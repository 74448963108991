.waiting-room {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);
  padding: 2rem;
  background: var(--bg-gradient);
  color: var(--text-primary);
}

.waiting-room-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.waiting-room h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
}

.waiting-room-status {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: var(--text-secondary);
}

.waiting-room-info {
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.waiting-room-players {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--text-secondary);
}

.waiting-room-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.waiting-room button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.join-button {
  background: var(--primary-color);
  color: white;
}

.join-button:hover {
  background: var(--primary-color-dark);
  transform: translateY(-2px);
}

.cancel-button {
  background: var(--danger-color, #dc3545);
  color: white;
}

.cancel-button:hover {
  background: var(--danger-color-dark, #bd2130);
  transform: translateY(-2px);
}

.waiting-room button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
}

.sound-preference {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sound-preference label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sound-preference input[type="checkbox"] {
  margin-right: 0.5rem;
  cursor: pointer;
  width: 18px;
  height: 18px;
  accent-color: var(--primary-color);
}

.sound-preference span {
  font-size: 0.9rem;
  color: var(--text-secondary);
}
