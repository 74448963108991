.leaflet-container {
  width: 100%;
  height: 100%;
  background: #13151a;
}

.leaflet-tile-pane {
  filter: brightness(0.6) saturate(0);
}

.leaflet-control-container {
  display: none;
}

:root {
  --primary-color: #6366f1;
  --success-color: #4CAF50;
  --danger-color: #f44336;
  --text-primary: #ffffff;
  --text-secondary: #94a3b8;
}

.multiplayer-game {
  min-height: 100vh;
  padding: 1rem;
  background-image: linear-gradient(to right, #2f343a, #2f343a);
  color: #ffffff;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #2f343a;
  border-radius: 0.5rem;
  backdrop-filter: blur(0.5rem);
}

.player-score {
  text-align: center;
  padding: 0.5rem;
  width: 33.33%;
}

.player-score h3 {
  margin: 0;
  font-size: 1.125rem;
  color: #94a3b8;
}

.player-score p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.game-info {
  text-align: center;
}

.game-info h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #ffffff;
}

.round-timer {
  font-size: 1.5rem;
  font-weight: bold;
  color: #3498db;
  margin-top: 0.25rem;
}

.game-content {
  max-width: 28rem;
  margin: 0 auto;
  text-align: center;
}

.audio-player {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.audio-player audio {
  width: 100%;
  max-width: 28rem;
}

.round-results {
  background-color: #2f343a;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.player-result {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 0.25rem;
  background-color: #2f343a;
  border-radius: 0.25rem;
}

.correct {
  color: #4CAF50;
}

.incorrect {
  color: #f44336;
}

.waiting-message {
  font-size: 1.125rem;
  color: #94a3b8;
  margin-bottom: 1rem;
  animation: pulse 2s infinite;
}

.next-round-countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient(to right, #2f343a, #2f343a);
}

.countdown-number {
  font-size: 3.75rem;
  font-weight: bold;
  color: #3498db;
  animation: scale 2s infinite;
}

.language-options {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.language-options {
  grid-template-columns: repeat(2, 1fr);
}

.language-options {
  grid-template-columns: repeat(3, 1fr);
}

.country-tooltip {
  background: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.country-tooltip::before {
  border-top-color: rgba(0, 0, 0, 0.8);
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
