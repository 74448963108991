/* Audio Samples Shared Styles */
.audio-sample-submission,
.user-audio-samples,
.audio-sample-voting,
.admin-audio-samples {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.submission-container,
.samples-container,
.voting-container,
.admin-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  width: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--text-primary, #ffffff);
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-primary, #ffffff);
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary, #ffffff);
}

.error-message {
  background-color: rgba(220, 53, 69, 0.2);
  color: #ff6b6b;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.success-message {
  background-color: rgba(40, 167, 69, 0.2);
  color: #51cf66;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.loading-message {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary, #cccccc);
  font-style: italic;
}

/* Audio Sample Submission Styles */
.tutorial-section {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.tutorial-steps {
  list-style: decimal;
  padding-left: 1.5rem;
}

.tutorial-steps li {
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.vocaroo-link {
  color: #4299e1;
  text-decoration: underline;
  transition: color 0.2s;
}

.vocaroo-link:hover {
  color: #63b3ed;
}

.submission-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  }

.form-group label {
  font-weight: 600;
  color: #e9e9e9;
}

.form-input,
.form-textarea,
.form-select {
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-primary, #ffffff);
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.2s, background-color 0.2s;
}

.form-input:focus,
.form-textarea:focus,
.form-select:focus {
  outline: none;
  border-color: var(--primary-color, #4299e1);
  background: rgba(255, 255, 255, 0.15);
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
}

.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.submit-button {
  padding: 0.8rem 2rem;
  background: var(--primary-color, #4299e1);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.submit-button:hover {
  background: var(--primary-color-dark, #3182ce);
  transform: translateY(-2px);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
}

.submission-guidelines {
  margin-top: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
}

.submission-guidelines ul {
  list-style: disc;
  padding-left: 1.5rem;
}

.submission-guidelines li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

/* User Audio Samples Styles */
.stats-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  margin-bottom: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary, #cccccc);
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color, #4299e1);
}

.no-samples-message {
  text-align: center;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.submit-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: var(--primary-color, #4299e1);
  color: white;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.submit-link:hover {
  background: var(--primary-color-dark, #3182ce);
}

.audio-samples-list,
.audio-samples-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.audio-sample-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sample-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sample-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

.status-badge.pending {
  background-color: #f0ad4e;
  color: #212529;
}

.status-badge.approved {
  background-color: #5bc0de;
  color: #212529;
}

.status-badge.rejected {
  background-color: #d9534f;
  color: white;
}

.status-badge.in-game {
  background-color: #5cb85c;
  color: white;
}

.sample-audio {
  width: 100%;
}

.audio-player {
  width: 100%;
  height: 40px;
}

.sample-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-label {
  font-size: 0.9rem;
  color: #ededed;
}

.detail-value {
  font-size: 0.95rem;
  color: #cecece;
}

.votes-container {
  margin-top: 0.5rem;
}

.votes {
  display: flex;
  gap: 1rem;
}

.upvotes {
  color: #5cb85c;
}

.downvotes {
  color: #d9534f;
}

.rejection-reason {
  background-color: rgba(217, 83, 79, 0.1);
  padding: 0.5rem;
  border-radius: 5px;
}

/* Audio Sample Voting Styles */
.voting-intro {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.6;
}

.filter-section {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  min-width: 150px;
}

.filter-input,
.filter-select {
  padding: 0.5rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-primary, #ffffff);
}

.voting-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.votes-display {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 1.1rem;
}

.voting-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.vote-button {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.vote-button.upvote {
  background-color: rgba(92, 184, 92, 0.2);
  color: #5cb85c;
}

.vote-button.upvote:hover {
  background-color: rgba(92, 184, 92, 0.3);
}

.vote-button.downvote {
  background-color: rgba(217, 83, 79, 0.2);
  color: #d9534f;
}

.vote-button.downvote:hover {
  background-color: rgba(217, 83, 79, 0.3);
}

.vote-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.vote-button.voting {
  opacity: 0.7;
}

.vote-success {
  text-align: center;
  color: #5cb85c;
  font-size: 0.9rem;
}

.vote-error {
  text-align: center;
  color: #d9534f;
  font-size: 0.9rem;
}

.login-to-vote {
  text-align: center;
  margin-top: 0.5rem;
}

.login-to-vote a {
  color: var(--primary-color, #4299e1);
  text-decoration: underline;
}

/* Admin Audio Samples Styles */
.admin-samples-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.admin-sample-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
}

.admin-actions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-notes-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #ededed;
}

.admin-notes-input {
  padding: 0.5rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-primary, #ffffff);
  resize: vertical;
}

.status-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.status-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.status-button.approve {
  background-color: rgba(92, 184, 92, 0.2);
  color: #5cb85c;
}

.status-button.approve:hover {
  background-color: rgba(92, 184, 92, 0.3);
}

.status-button.reject {
  background-color: rgba(217, 83, 79, 0.2);
  color: #d9534f;
}

.status-button.reject:hover {
  background-color: rgba(217, 83, 79, 0.3);
}

.status-button.in-game {
  background-color: rgba(240, 173, 78, 0.2);
  color: #f0ad4e;
}

.status-button.in-game:hover {
  background-color: rgba(240, 173, 78, 0.3);
}

.status-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.update-success {
  color: #5cb85c;
  font-size: 0.9rem;
  text-align: center;
}

.update-error {
  color: #d9534f;
  font-size: 0.9rem;
  text-align: center;
}

/* Stats Overview Styles */
.stats-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  flex: 1;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.stat-card.pending {
  border-left: 4px solid #f0ad4e;
}

.stat-card.approved {
  border-left: 4px solid #5bc0de;
}

.stat-card.rejected {
  border-left: 4px solid #d9534f;
}

.stat-card.in-game {
  border-left: 4px solid #5cb85c;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1rem;
  color: var(--text-secondary, #cccccc);
  margin-bottom: 1rem;
}

.stat-action {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 5px;
  color: var(--text-primary, #ffffff);
  cursor: pointer;
  transition: background-color 0.2s;
}

.stat-action:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.2);
}

.stat-action:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Sample Title Link Styles */
.sample-title-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}

.sample-title-link:hover h3 {
  color: var(--primary-color, #4299e1);
}

.view-details-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(66, 153, 225, 0.2);
  color: var(--primary-color, #4299e1);
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.view-details-link:hover {
  background: rgba(66, 153, 225, 0.3);
}

/* Vocaroo Player Styles */
.vocaroo-player {
  width: 100%;
  height: 80px;
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
}

/* Audio Sample Details Styles */
.audio-sample-details {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.details-container {
  width: 100%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  color: white;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
}

.details-header h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(90deg, #51a2ff,#7bf1a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.back-button {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.audio-player-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.audio-player-container iframe {
  width: 100%;
  height: 80px;
  border-radius: 0.5rem;
}

.sample-info {
  margin-bottom: 2rem;
}

.info-row {
  display: flex;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 0.75rem;
}

.info-label {
  width: 120px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.info-value {
  flex: 1;
}

.info-value.transcription,
.info-value.notes,
.info-value.admin-feedback {
  white-space: pre-line;
}

.voting-section {
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;
}

.votes-display {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.vote-count {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.vote-icon {
  font-size: 1.5rem;
}

.vote-number {
  font-size: 1.25rem;
  font-weight: 700;
}

.upvotes .vote-number {
  color: #4caf50;
}

.downvotes .vote-number {
  color: #f44336;
}

.vote-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.vote-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s;
}

.vote-button.upvote {
  background: rgba(76, 175, 80, 0.2);
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.vote-button.upvote:hover {
  background: rgba(76, 175, 80, 0.3);
}

.vote-button.downvote {
  background: rgba(244, 67, 54, 0.2);
  color: #f44336;
  border: 1px solid rgba(244, 67, 54, 0.3);
}

.vote-button.downvote:hover {
  background: rgba(244, 67, 54, 0.3);
}

.vote-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-controls {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 2rem;
}

.admin-controls h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #ccc;
}

.feedback-input {
  margin-bottom: 1.5rem;
}

.feedback-input label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.feedback-input textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem;
  padding: 0.75rem;
  color: white;
  resize: vertical;
}

.status-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.status-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 600;
  flex: 1;
  min-width: 120px;
  transition: all 0.3s;
}

.status-button.approve {
  background: rgba(76, 175, 80, 0.2);
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.status-button.approve:hover {
  background: rgba(76, 175, 80, 0.3);
}

.status-button.reject {
  background: rgba(244, 67, 54, 0.2);
  color: #f44336;
  border: 1px solid rgba(244, 67, 54, 0.3);
}

.status-button.reject:hover {
  background: rgba(244, 67, 54, 0.3);
}

.status-button.in-game {
  background: rgba(156, 39, 176, 0.2);
  color: #9c27b0;
  border: 1px solid rgba(156, 39, 176, 0.3);
}

.status-button.in-game:hover {
  background: rgba(156, 39, 176, 0.3);
}

.status-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.audio-sample-details.loading,
.audio-sample-details.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #9c27b0;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  background: rgba(244, 67, 54, 0.2);
  border: 1px solid rgba(244, 67, 54, 0.3);
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
  color: white;
}

.error-message button {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.error-message button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .audio-sample-submission,
  .user-audio-samples,
  .audio-sample-voting,
  .admin-audio-samples {
    padding: 1rem;
  }
  
  .submission-container,
  .samples-container,
  .voting-container,
  .admin-container {
    padding: 1.5rem;
  }
  
  .audio-samples-grid {
    grid-template-columns: 1fr;
  }
  
  .stats-summary {
    flex-direction: column;
    align-items: center;
  }
  
  .filter-controls {
    flex-direction: column;
  }
}
