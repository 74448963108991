.update-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideIn 0.5s ease-out;
}

.update-popup-content {
  background: linear-gradient(135deg, #2a3f5f 0%, #1e2b45 100%);
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 300px;
  border: 1px solid #3a5280;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #ff9c9c;
}

.update-popup h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #7fdbff;
}

.update-popup p {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.4;
}

.try-now-button {
  display: inline-block;
  background: linear-gradient(135deg, #4CAF50 0%, #2E7D32 100%);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

.try-now-button:hover {
  background: linear-gradient(135deg, #5CBF60 0%, #3E8D42 100%);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes slideIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
